import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`TextInput is a form component to add default styling to the native text input. `}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Don't forget to set `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to make the TextInput accessible to screen reader users.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<TextInput aria-label="Zipcode" name="zipcode" placeholder="Zipcode" autoComplete="postal-code" />

<TextInput ml={4} icon={Search} aria-label="Zipcode" name="zipcode" placeholder="Find user" autoComplete="postal-code" />
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`TextInput components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p>{`Native `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` attributes are forwarded to the underlying React `}<inlineCode parentName="p">{`input`}</inlineCode>{` component and are not listed below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`aria-label`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Required. Allows input to be accessible.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`block`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Adds `}<inlineCode parentName="td">{`display: block`}</inlineCode>{` to element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Can be either `}<inlineCode parentName="td">{`small`}</inlineCode>{` or `}<inlineCode parentName="td">{`large`}</inlineCode>{`. Creates a smaller or larger input than the default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String or Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set the width of the input`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`maxWidth`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String or Number or `}<a parentName="td" {...{
              "href": "https://styled-system.com/guides/array-props"
            }}>{`Array`}</a></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set the maximum width of the input`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`minWidth`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String or Number or `}<a parentName="td" {...{
              "href": "https://styled-system.com/guides/array-props"
            }}>{`Array`}</a></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set the minimum width of the input`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Node (pass Octicon react component)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Icon to be used inside of input. Positioned on the left edge.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      